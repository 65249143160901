<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    //console.log('moment', this.$moment().format("YYYY-MM-DD HH:mm:ss"))
  }
}
</script>
<style lang="scss">
  body {
    font-family: "Helvetica Neue", Helvetica, Arial, "Droid Sans", sans-serif;
    font-size: 14px;
    background-color: #383838;
  }
  /* Import Font Awesome 5 Icons Set */
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
  @import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
  @import '~@fortawesome/fontawesome-free/scss/solid.scss';
  @import '~@fortawesome/fontawesome-free/scss/regular.scss';
  @import '~@fortawesome/fontawesome-free/scss/brands.scss';
</style>
