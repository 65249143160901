import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
//import moment from "moment"

Vue.config.productionTip = false

/*
import api from "@/services/apiService"
api.post('servertime', {datetime: moment().format("YYYY-MM-DD HH:mm:ss")})
.then((response)=>{
  let data = response.data
  if(data.result === 'success') {
    moment.now = function() {
      return data.diff + Date.now()
    }
    Vue.prototype.$moment = moment
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
*/

import moment from "./moment.js"
Vue.prototype.$moment = moment

Vue.filter('amountFormat', (num, dec=2, empty='-') => {
  if(!num)
    return empty
  return parseFloat(num).toFixed((dec||0)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
